import React from "react";
import Router from "./router";
function App() {


  return (
    <div>
      <Router />
    </div>
  );
}

export default App;
