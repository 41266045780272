import React from "react";
import { Link } from "react-router-dom"; // Assuming you're using react-router for navigation

function PrivacyPolicy() {
  return (
    <div className="relative p-[10px] h-screen w-screen bg-gradient-to-b from-[#FCFBFC] to-[#C7C7E3]">
      <header className="flex flex-col items-center justify-center h-full">
        <h1 className="text-3xl font-bold mb-4">Privacy Policy</h1>

        <div className="bg-white p-4 rounded-xl shadow-md mb-4 max-w-prose">
          <h2 className="text-xl font-semibold mb-2">Introduction</h2>
          <p>
            Welcome to ScreenQuery. This privacy policy outlines our practices
            regarding the collection, use, and disclosure of information when
            you use our application. We are committed to respecting your privacy
            and ensuring the protection of your personal data.
          </p>
          <h2 className="text-xl font-semibold mb-2">
            Information Collection and Use
          </h2>
          <p>
            For a better experience while using ScreenQuery, we may require you
            to provide us with certain personally identifiable information.
            However, ScreenQuery operates with a commitment to user privacy:
            <ul>
              <li>
                Screenshots: ScreenQuery takes screenshots only when the user
                initiates the action by clicking the button. These screenshots
                are not stored, transmitted, nor are they accessible by the
                author or any third parties.
              </li>
              <li>
                ScreenQuery does not collect any personally identifiable
                information.
              </li>
              <li>
                ScreenQuery does not collect any information about the user's
                device.
              </li>
              <li>
                ScreenQuery does not collect any information about the user's
                location.
              </li>
              <li>
                ScreenQuery does not collect any information about the user's
                browsing history.
              </li>
            </ul>
            <h2 className="text-xl font-semibold mb-2">
              Data Storage and Security
            </h2>
            <ul>
              <li>
                No Storage of Images: ScreenQuery does not store any images
                taken by the application. The screenshots are used temporarily
                for the purpose of answering the user's query and are then
                discarded.
              </li>
              <li>
                Security Measures: We employ a variety of security measures to
                ensure the safety of any personal information provided by users.
              </li>
            </ul>
            <h2 className="text-xl font-semibold mb-2">
              Changes to This Privacy Policy
            </h2>
            <p>
              We may update our Privacy Policy from time to time. We will notify
              you of any changes by posting the new Privacy Policy on this page.
              You are advised to review this Privacy Policy periodically for any
              changes.
            </p>
            <h2 className="text-xl font-semibold mb-2">
            Contact Us
            </h2>
            <p>
            If you have any questions or suggestions about our Privacy Policy, do not hesitate to contact us. contact@732development.com
            </p>
          </p>
          
        </div>

        <Link to="/" className="text-blue-600 hover:text-blue-800">
          Back to Home
        </Link>
      </header>
    </div>
  );
}

export default PrivacyPolicy;
