import React from "react";
import { Helmet } from "react-helmet";
import {
  FaCode,
  FaBook,
  FaUser,
  FaSearch,
  FaPencilAlt,
  FaChartLine,
} from "react-icons/fa";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";

const downloadUrl =
  "https://github.com/RomanczuG/ask-screen-autoupdater/releases/download/0.1.7/ScreenQuery-0.1.7-universal.dmg";

const Home = () => (
  <div className="flex flex-col min-h-screen bg-gradient-to-b from-[#FCFBFC] to-[#C7C7E3]">
    <Helmet>
      <title>ScreenQuery - Your On-Screen Guide</title>
      <meta
        name="description"
        content="FREE APP - ScreenQuery helps you understand and interact with any content on your screen. Just type your question and get immediate, context-aware answers. Free for developers, students, and casual users."
      />
      <meta
        name="keywords"
        content="free, ScreenQuery, on-screen guide, screen assistance, interactive help, screen query tool, free tool, developer tool, student tool, screen interaction"
      />
      <meta property="og:title" content="ScreenQuery - Your On-Screen Guide" />
      <meta
        property="og:description"
        content="The ultimate tool for interactive on-screen assistance. Free and easy to use for developers, students, and casual users."
      />
      <meta property="og:type" content="website" />
      <meta
        property="twitter:title"
        content="ScreenQuery - FREE On-Screen Guide"
      />
      <meta
        property="twitter:description"
        content="ScreenQuery: Your FREE On-Screen Assistant. FREE - ScreenQuery helps you understand and interact with any content on your screen. Just type your question and get immediate, context-aware answers. Perfect for developers, students, and casual users."
      />
    </Helmet>

    <Header />
    <HeroSection />
    <FeaturesSection />
    <DownloadSection />
    <Footer />
  </div>
);

const Header = () => (
  <nav className="flex items-center justify-between p-4 shadow-md bg-white">
    <div className="flex items-center">
      <img src="/logo.png" alt="ScreenQuery Logo" className="h-8 mr-2" />
      <h1 className="text-xl font-bold text-[#491CD4]">ScreenQuery</h1>
    </div>
    <div className="flex space-x-4">
      <Link
        to="/about"
        className="text-[#491CD4] hover:text-[#AD69FC] transition-colors"
      >
        About
      </Link>
      <Link
        to="/contact"
        className="text-[#491CD4] hover:text-[#AD69FC] transition-colors"
      >
        Contact
      </Link>
    </div>
  </nav>
);

const HeroSection = () => (
  <motion.section
    className="flex-1 flex flex-col md:flex-row items-center justify-evenly p-10 text-center md:text-left bg-white shadow-xl"
    initial={{ opacity: 0 }}
    animate={{ opacity: 1 }}
    transition={{ duration: 1 }}
    style={{ minHeight: "60vh" }}
  >
    <div className="flex-1 mb-4 md:mb-0 md:max-w-md lg:max-w-lg mx-auto">
      {" "}
      {/* Adjust the max width for medium and large screens */}
      <h2 className="text-5xl font-bold mb-4 text-[#491CD4]">
        Instant On-Screen Help for Every Task
      </h2>
      <p className="text-lg leading-relaxed mb-6">
        Whether you're coding, studying, writing, or analyzing data, ScreenQuery
        is your go-to on-screen assistant.
      </p>
      <a
        href={downloadUrl}
        className="inline-block bg-[#AD69FC] hover:bg-[#491CD4] text-white font-bold py-3 px-6 rounded-full transition-colors"
      >
        Download Now
      </a>
    </div>
    {/* Responsive Video Container */}
    <div className="w-full md:max-w-md lg:max-w-lg mx-auto">
      <div
        className="aspect-ratio-box"
        style={{ position: "relative", width: "100%", paddingTop: "56.25%" }}
      >
        <iframe
          style={{
            position: "absolute",
            top: "0",
            left: "0",
            width: "100%",
            height: "100%",
          }}
          src="https://www.youtube.com/embed/Mcm4iAvppQY?si=BLeiBJTZ0cV4R6AZ"
          title="ScreenQuery YouTube Video"
          frameborder="0"
          allowfullscreen
        ></iframe>
      </div>
    </div>
  </motion.section>
);

const FeaturesSection = () => (
  <section className="py-10 px-4 bg-[#F1F1F7]">
    <h3 className="text-4xl font-bold text-center mb-12 text-[#491CD4]">
      Why Choose ScreenQuery?
    </h3>
    <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
      <FeatureCard
        icon={<FaCode />}
        title="For Developers"
        description="Get coding solutions instantly."
      />
      <FeatureCard
        icon={<FaBook />}
        title="For Students"
        description="Understand complex concepts easily."
      />
      <FeatureCard
        icon={<FaUser />}
        title="For Everyone"
        description="Navigate screens with ease."
      />
      <FeatureCard
        icon={<FaSearch />}
        title="For Researchers"
        description="Effortlessly look up references and methodologies."
      />
      <FeatureCard
        icon={<FaPencilAlt />}
        title="For Writers"
        description="Get inspiration and writing assistance."
      />
      <FeatureCard
        icon={<FaChartLine />}
        title="For Finance Professionals"
        description="Analyze financial terms and data quickly."
      />
    </div>
  </section>
);

const FeatureCard = ({ icon, title, description }) => (
  <motion.div
    className="bg-white p-6 rounded-lg shadow-md hover:shadow-lg"
    whileHover={{ scale: 1.05 }}
  >
    <div className="text-3xl mb-4 text-[#AD69FC]">{icon}</div>
    <h4 className="font-semibold text-xl mb-2">{title}</h4>
    <p>{description}</p>
  </motion.div>
);

const DownloadSection = () => (
  <section className="bg-[#F1F1F7] p-10 text-center">
    <h3 className="text-3xl font-bold mb-4 text-[#491CD4]">
      Get Started with ScreenQuery
    </h3>
    <p className="mb-6">
      Download the app and revolutionize your screen interaction today.
    </p>
    <a
      href={downloadUrl}
      className="bg-[#AD69FC] hover:bg-[#491CD4] text-white py-3 px-6 rounded-full font-bold transition-all"
    >
      Download Now
    </a>
  </section>
);

const Footer = () => (
  <footer className="text-center py-6 bg-white">
    <div className="text-sm mb-4">
      <Link
        to="/privacy-policy"
        className="mx-2 text-[#491CD4] hover:text-[#AD69FC]"
      >
        Privacy Policy
      </Link>
      <Link
        to="/terms-and-conditions"
        className="mx-2 text-[#491CD4] hover:text-[#AD69FC]"
      >
        Terms and Conditions
      </Link>
    </div>
    <p className="mb-2 text-xs">
      Lost on your screen? We're here to guide you ❤️
    </p>
    <p className="mb-2 text-xs">Copyright © 2023 - All right reserved</p>
    <p className="text-xs">
      Made by Mateusz, follow me on
      <a
        href="https:twitter.com/betterMateusz"
        target="_blank"
        rel="noopener noreferrer"
        className="text-[#491CD4] hover:text-[#AD69FC]"
      >
        {" "}
        Twitter
      </a>
    </p>
  </footer>
);

export default Home;
