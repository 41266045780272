import React from "react";
import { Link } from "react-router-dom"; // If you're using React Router

function TermsAndConditions() {
  return (
    <div className="relative p-[10px] h-screen w-screen bg-gradient-to-b from-[#FCFBFC] to-[#C7C7E3]">
      <header className="flex flex-col items-center justify-center h-full">
        <h1 className="text-3xl font-bold mb-4">Terms and Conditions</h1>

        <div className="bg-white p-4 rounded-xl shadow-md mb-4 max-w-prose">
          <h2 className="text-xl font-semibold mb-2">Welcome to ScreenQuery</h2>
          <p>
            These Terms and Conditions ("Terms") govern your access to and use
            of ScreenQuery. By creating an account and using the application,
            you agree to be bound by these Terms.
          </p>
          <h3 className="font-semibold">Account and Subscription</h3>
          <p>
            To use ScreenQuery, you must register for an account and maintain
            an active subscription service. Use of ScreenQuery is subject to
            subscription fees, as detailed in our pricing policy.
          </p>
          <h3 className="font-semibold">User Conduct</h3>
          <p>
            You agree to use ScreenQuery only for lawful purposes and not
            engage in any use that is illegal, fraudulent, or harmful. You are
            prohibited from using the app in any way that interferes with its
            normal operation or with any other user's use and enjoyment of the
            app.
          </p>
          <h3 className="font-semibold">Termination</h3>
          <p>
            We reserve the right to terminate or suspend your account and
            access to the application if you are found to be in violation of
            these Terms.
          </p>
          <h3 className="font-semibold">Changes to Terms</h3>
          <p>
            We may amend these Terms at any time. You are advised to review
            these Terms periodically for any changes.
          </p>
          <h3 className="font-semibold">Contact Us</h3>
          <p>If you have any questions about these Terms, please contact us.</p>
        </div>

        <Link to="/" className="text-blue-600 hover:text-blue-800">
          Back to Home
        </Link>
      </header>
    </div>
  );
}

export default TermsAndConditions;
